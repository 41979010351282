<template>
    <div class="about-page">
        <section class="about-content">
        <h2 style="color: #ff2d55;">About Math Game</h2>
        <p><b>Engage your mind</b><br>
        Interactive math challenges designed for learners of all ages.</p>

        <br><b>Features</b><br>
        <p>Various difficulty levels for progressive learning and skill improvement.<br>
            Operations include addition, subtraction, multiplication, and division.<br>
            Score tracking to motivate and monitor progress.<br>
            Engaging design to enhance focus and enjoyment.</p>

        <br><b>How to Play</b><br>
        <p>1. Set your preferred difficulty level using the slider.<br>
            2. Choose an operation to practice: addition, subtraction, multiplication, or division.<br>
            3. Solve the question displayed by selecting the correct answer from multiple choices.<br>
            4. Try to improve your score with each correct answer!</p>
        </section>

        <section class="terms">
            <h2 style="color: #ff2d55;">Terms and Conditions</h2>
            <p><b>Data Privacy and Collection</b> <br>
             This app does not collect, process, or transmit any personal data or any other type of user data. All calculations and interactions occur locally on your device, with no data transmitted to external servers or third parties.
            <br><br><b>Functionality and Scope</b><br>The purpose of this app is solely to provide interactive math challenges. It operates independently on your device without requiring any online connectivity, meaning there are no network requests or external data exchanges.
            <br><br><b>User Privacy Assurance</b><br>We respect your privacy fully and have designed this app to operate without needing any personal information or device data. You can use this app confidently, knowing that no user data is tracked, saved, or shared.
            <br><br><b>App Updates</b><br>Any updates will focus on enhancing the app experience and improving functionality. We are committed to maintaining a safe and private environment for all users.</p>
        </section>

      <section class="screenshots">
        <h2>Screenshots</h2>
        <div class="screenshot-container">
          <img src="https://offsidecompass.lon1.cdn.digitaloceanspaces.com/simpleapp/Screenshot%202024-11-09%20at%2012.19.04.png" alt="Math Game - Main Menu" />
          <img src="https://offsidecompass.lon1.cdn.digitaloceanspaces.com/simpleapp/Screenshot%202024-11-09%20at%2012.19.18.png" alt="Math Game - Difficulty Selection" />
          <img src="https://offsidecompass.lon1.cdn.digitaloceanspaces.com/simpleapp/Screenshot%202024-11-09%20at%2012.19.32.png" alt="Math Game - Gameplay" />
          <img src="https://offsidecompass.lon1.cdn.digitaloceanspaces.com/simpleapp/Screenshot%202024-11-09%20at%2012.19.49.png" alt="Math Game - Score Tracking" />
        </div>
      </section>

      <section class="support">
        <p>For support, reach out to us at <a href="mailto:info@offsidecompass.co">info@offsidecompass.co</a></p>
      </section>

      <footer class="about-footer">
        <p>&copy; 2024 Iordanis Paschalidis. All rights reserved.</p>
      </footer>
    </div>
  </template>

<script>
export default {
  name: 'SimnpleMath'
}
</script>

  <style scoped>
  .terms p {
    max-width: 600px; /* Adjust width as needed */
    margin: 0 auto;    /* Centers the paragraph */
    text-align: left;  /* Keeps text left-aligned */
    line-height: 1.6;  /* Optional: improves readability */
    }
  .about-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #ffffff;
    background-color: #333; /* Dark background for contrast */
  }

  .about-header h1 {
    color: #ffffff;
    font-size: 1.8em;
  }

  .features ul {
    list-style-type: square;
    margin: 10px 0;
    padding: 0;
  }

  .features li {
    margin: 5px 0
  }

  .instructions {
    margin-top: 15px;
  }

  .instructions p {
    margin: 5px 0;
  }

  .screenshots {
    margin-top: 20px;
    text-align: center;
  }

  .screenshots h2 {
    color: #ffffff;
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .screenshot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  .screenshot-container img {
    width: 150px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  .about-footer {
    margin-top: auto; /* Pushes the footer to the bottom */
    font-size: 0.9em;
    color: #ffffff;
    text-align: center;
    padding-top: 20px;
  }

  .about-content {
  max-width: 800px;
  margin: 0 auto;
  color: #ffffff;
  text-align: left;
}

.about-content h2 {
  color: #ff2d55;
}

.about-content p {
  margin-bottom: 10px;
}
</style>
