<template>
  <div class="home">

     <shell
      :banner="banner"
      :shell_input="send_to_terminal"
      :commands="commands"
      @shell_output="prompt"
    ></shell>
  </div>
</template>

<script>
import Shell from 'vue-shell'
import pdfFile from '@/assets/iordaniscv.pdf'
import { saveAs } from 'file-saver'

export default {
  name: 'Home',
  components: {
    Shell
  },
  data () {
    return {
      send_to_terminal: '',
      banner: {
        header: 'Iordanis Paschalidis',
        subHeader: 'Software Engineer',
        helpHeader: 'Type \'h\' to see all available commands',
        emoji: {
          time: 750
        },
        sign: '➜'
      },
      commands: [
        {
          name: 'info',
          get () {
            var today = new Date()
            var birthDate = new Date('01/01/1993')
            var age = today.getFullYear() - birthDate.getFullYear()
            var m = today.getMonth() - birthDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--
            }
            return '<p style="text-align:left;">Iordanis Paschalidis age ' + age + ' from Greece living in London </p>'
          }
        },
        {
          name: 'employment',
          get () {
            var message = '<p style="text-align:left;">Integration Developer, <a href="https://www.broadbean.com/" target="_blank">Broadbean</a>, Feb 2016 - Oct 2017  </p>'
            message = message + '<p style="text-align:left;">Software Engineer, <a href="https://www.boclips.com/" target="_blank">Boclips</a>, Oct 2017 - Apr 2018 </p>'
            message = message + '<p style="text-align:left;">Software Engineer, <a href="https://www.broadbean.com/" target="_blank">Broadbean</a>, Apr 2018 - Oct 2020   </p>'
            message = message + '<p style="text-align:left;">Senior Software Engineer, <a href="https://paiger.co/" target="_blank">Paiger</a>, Oct 2020 - Aug 2021  </p>'
            message = message + '<p style="text-align:left;">Software Engineer, <a href="https://www.curve.com/en-gb/" target="_blank">Curve</a>, Aug 2021 - Dec 2022 </p>'
            message = message + '<p style="text-align:left;">Senior Software Engineer, <a href="https://www.shell.com/" target="_blank">Shell (Contract)</a>, Jan 2023 - present </p>'
            return message
          }
        },
        {
          name: 'projects',
          get () {
            var message = '<p style="text-align:left;">Error handling go library <a href="https://github.com/junkd0g/neji" target="_blank">repo</a></p>'
            message = message + '<p style="text-align:left;">Logger go library<a href="https://github.com/junkd0g/applogger" target="_blank">repo</a> </p>'
            message = message + '<p style="text-align:left;">RSS go client <a href="https://github.com/junkd0g/karoo" target="_blank">repo/a></p>'
            message = message + '<p style="text-align:left;"OpenAI go client:  <a href="https://github.com/junkd0g/vegapunk" target="_blank">repo</a> </p>'
            return message
          }
        },
        {
          name: 'h',
          get () {
            var message = '<p style="text-align:left;">commands:</p>'
            message = message + '<p style="text-align:left;">projects - displays info about my projects </p>'
            message = message + '<p style="text-align:left;">employment - displays info about my employment history</p>'
            message = message + '<p style="text-align:left;">info - info about Iordanis</p>'
            message = message + '<p style="text-align:left;">downloadcv - downloads pdf version of my cv</p>'
            message = message + '<p style="text-align:left;">h -  commands descriptions</p>'
            return message
          }
        },
        {
          name: 'i',
          get () {
            var message = '<p style="text-align:left;">commands:</p>'
            message = message + '<p style="text-align:left;">projects - displays info about my projects </p>'
            message = message + '<p style="text-align:left;">employment - displays info about my employment history</p>'
            message = message + '<p style="text-align:left;">info - info about Iordanis</p>'
            message = message + '<p style="text-align:left;">downloadcv - downloads pdf version of my cv</p>'
            message = message + '<p style="text-align:left;">h -  commands descriptions</p>'
            return message
          }
        },
        {
          name: 'downloadcv',
          get () {
            fetch(pdfFile)
              .then(resp => resp.blob())
              .then(blob => {
                saveAs(blob, 'iordaniscv.pdf')
                this.tempMessage = '<p style="text-align:left;">Iordanis\' CV is being downloaded...</p>'
              })
              .catch(err => {
                console.error('Error downloading the file:', err)
                this.tempMessage = '<p style="text-align:left;">Error downloading Iordanis\' CV. Please try again later.</p>'
              })
            return this.tempMessage || '<p style="text-align:left;">Iordanis\' CV has being downloaded</p>'
          }
        }
      ]
    }
  },
  methods: {
    prompt (value) {
      if (value === 'node -v') {
        this.send_to_terminal = process.versions.node
      }
    },
    downloadCV () {
      const link = document.createElement('a')
      link.href = pdfFile
      link.setAttribute('download', 'iordaniscv.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

}
</script>
