import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import faviconImage from '@/assets/dev.png'

Vue.config.productionTip = false

new Vue({
  data: {
    title: 'Iordanis Paschalidis',
    faviconURL: faviconImage
  },
  watch: {
    title: function (newTitle) {
      document.title = newTitle
    },
    faviconURL: function (newURL) {
      this.setFavicon(newURL)
    }
  },
  methods: {
    setFavicon (url) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/png'
      link.rel = 'shortcut icon'
      link.href = url
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  },
  mounted () {
    document.title = this.title
    this.setFavicon(this.faviconURL)
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
